.form-control {
  font-family: Helvetica, Arial, sans-serif;
  display: block;
  width: 100%;
  padding: 8px 10px;
  font-size: 15px;
  font-weight: bold;
  // font-weight: 400;
  // color: #abb7c6;
  // background-color: #fff;
  background-color: #f7f7f7;
  // background-clip: padding-box;
  border-radius: 0px;
  border: 1px solid #abb7c6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  height: auto;

  &:focus {
    color: #212529;
    // background-color: #fff;
    background-color: #d7d7d7;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }

  &:focus-within {
    color: #212529;
    // background-color: #fff;
    background-color: #e3faff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }

  .react-select__control {
    padding: 0px;
    width: 100%;
    background: transparent;
    border: 1px solid transparent;
    box-shadow: none;

    &:hover {
      border: 1px solid transparent;
    }
  }

  .react-select__control:focus-within {
    border-color: transparent;
    box-shadow: none;
  }

  .focus-none .react-select__control,
  .focus-none .react-select__control:focus-within {
    box-shadow: none;
    outline: none;
    border: none;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .react-select__value-container {
    height: 35px;
    min-height: 35px;
    padding: 0 5px;
  }

  .react-select__placeholder {
    font-weight: normal;
  }

  input {
    // For PhoneInput
    font-weight: bold !important;
    color: "red" !important;
    font-size: 15px;
  }

  input:disabled {
    // For PhoneInput
    border: none !important;
  }
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: normal;
  // color: red;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-weight: normal;
  // color: red;
}

.focus-none {
  // border-radius: 0px;
  outline: none;
  border: none;

  &:focus {
    // border-radius: 0px;
    border: none;
    box-shadow: none;
    outline: none !important;
  }

  &:focus-within {
    // border-radius: 0px;
    border: none;
    box-shadow: none;
    outline: none !important;
  }
}

.form-label {
  display: block;
  // margin-bottom: 0.5rem;
  font-size: 15px;
  margin-bottom: 2px;
}

.form {
  display: flex;
  justify-content: center;
  width: 100%;
}

.form__content {
  background: white;
  box-shadow: 2px 2px 20px 1px;
  border-radius: 0px;
  padding: 15px;
  margin: 20px 0px;

  @media (min-width: $tablet-breakpoint) {
    padding: 15px 20px;
    margin: 60px 0px;
  }

  h1 {
    font-size: $font-size-xlarge;
  }

  h2 {
    font-size: $font-size-large;
    color: #a30014;
  }

  h3 {
    font-weight: lighter;
    color: darkgreen;
    font-size: $font-size-medium;
  }
}

.form__content--no-shadow {
  padding: 25px 5px;
  padding-bottom: 30px;
  width: 100%;
  box-shadow: none;
  border: 1px solid lightgrey;

  @media (min-width: $tablet-breakpoint) {
    padding: 30px 20px;
  }
}

.form__content--small {
  width: 100%;
  max-width: 420px;
}
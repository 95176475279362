* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

// total number of unit to     number of round
// unit payment to   payment per hand
body {
  margin: 0;
  color: $dark-grey;
  font-family: Helvetica, Arial, sans-serif;
  // font-family: Gotham Black - Regular;
  font-size: $m-size;
  line-height: 1.6;
}

button {
  margin: 0px;
  cursor: pointer;
}

button:disabled {
  // cursor: default;
  opacity: 0.15;

  &:hover {
    opacity: 0.15;
    cursor: not-allowed;
  }
}

.is-active {
  font-weight: bold;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}


.text-decoration-none {
  text-decoration: none;
}
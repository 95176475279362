.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-scroll-x {
  width: 100%;
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.overflow-scroll-y {
  height: 100%;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none !important;
}

.flex-1 {
  flex: 1 !important;
  min-height: 100% !important;
  height: 100% !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.text-wrap {
  white-space: normal !important;
}

.font-weight-bold {
  font-weight: bold;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

// @include make-hidden();

// Row
// Rows contain your columns.
@if $enable-grid-classes {
  .row {
    @include make-row();

    > * {
      @include make-col-ready();
    }
  }
}

// Columns
// Common styles for small and large grid columns
@if $enable-grid-classes {
  @include make-grid-columns();
}

@include make-rotate();
@include make-color();
@include make-border();
@include make-border-round();
@include make-border-color();
@include make-fixed-height();
@include make-fixed-width();
@include make-min-width();
@include make-max-width();
@include make-stretch-full();
@include make-left-right-top-bottom();
@include make-left-right-top-bottom-percentage();
@include make-padding();
@include make-margin();
@include make-font-size();
@include make-line-height();
@include make-d-flex-display();
@include make-d-block-display();
@include make-hidden-d-flex();
@include make-hidden-d-block();
@include make-text-truncate();
@include make-hover();

.width-auto {
  width: auto !important;
}

.opacity-0 {
  opacity: 0;
}
.opacity-25 {
  opacity: 0.25;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-75 {
  opacity: 0.75;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-100 {
  opacity: 1;
}

.rounded-circle {
  border-radius: 50%;
}
.text-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.relative {
  position: relative;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
  z-index: 500;
}

.absolute {
  position: absolute;
  z-index: 400;
}

.transformX50 {
  transform: translateX(50%);
}
.transformX-50 {
  transform: translateX(-50%);
}

.transformY50 {
  transform: translateY(50%);
}
.transformY-50 {
  transform: translateY(-50%);
}

.transformX100 {
  transform: translateX(100%);
}
.transformX-100 {
  transform: translateX(-100%);
}

.transform50-50 {
  transform: translate(50%, 50%);
}
.transform-50-50 {
  transform: translate(-50%, -50%);
}

.transform-100-minus-100-minus {
  transform: translate(-100%, -100%);
}

.transform-50-minus-100-minus {
  transform: translate(-50%, -100%);
}

.transform-100-minus-50-minus {
  transform: translate(-100%, -50%);
}

.flip {
  // width: 30px;
  // display: inline-block;
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.content-container {
  margin: 0 auto;
  // padding: 0px 6px;
  width: 100%;
  max-width: 1500px;
  padding-top: 60px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 50px;
  // margin-top: 50px;

  @include media-breakpoint-up(sm) {
    // padding: 0px 20px;
    padding-top: 100px;
  }

  @include media-breakpoint-up(md) {
    // padding: 0px 20px;
    padding-top: 90px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 100px;
    // margin-top: 80px;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 80px;
    padding-right: 80px;
  }
}
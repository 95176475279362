.progress-bar {
    background: #f8ecda;
    // border-radius: 13px;
    height: 8px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.progress-bar span {
    content: "";
    display: block;
    background: #ad5790;
    height: 100%;
    // border-radius: 9px;
}

.btn {
  border: none;
  outline: none;
  // margin: 10px 0px;
  display: inline-block;
  // font-size: $font-size-md;
  // line-height: 1;
  border-radius: 0px;
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: transparent;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  opacity: 1;
  &:hover {
    opacity: 0.97;
    cursor: pointer;
  }
}

.btn:disabled {
  opacity: 0.15;
  &:hover {
    opacity: 0.15;
    cursor: not-allowed;
  }
}

.button {
  border: none;
  outline: none;
  border-radius: 0px;
  color: white;
  display: inline-block;
  background: $logo-magenta;
  font-size: $font-size-medium;
  line-height: 1;
  padding: $s-size;
  text-decoration: none;
  text-align: center;
  opacity: 0.97;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

// .dashboard-btn {
//   // font-weight: bold;
//   // font-size: $font-size-large;
//   //  padding: 18px;
//   text-align: center;
//    width: 150px;
// }

.dashboard-btn {
  @extend .button;
  // padding-left: 5px;
  // padding-right: 5px;
  font-size: 12px;
  // font-weight: bold;
  background: $orange;
  width: 140px;
  font-weight: bold;
}

.setup-btn {
  @extend .button;
  font-size: 12px;
  background: $orange;
  width: 100%;
  max-width: 180px;
}

.view-btn,
.edit-btn {
  @extend .button;
  width: 40px;
  border-radius: 0px;
  font-size: 10px;
  padding: 6px 0px;
  border: 1px solid $logo-magenta;
  background: $logo-magenta;
}
.view-btn {
  margin-right: 4px;
}
.edit-btn {
  margin-left: 4px;
  color: $logo-magenta;
  background: white;
}
.button--primary {
  // font-weight: bold;
  // font-size: $font-size-large;
  padding: 18px;
  // width: 150px;
}

.btn-group {
  justify-content: flex-end;
  display: flex;
  padding: 14px 0px;
}

.button--primary-left,
.button--primary-right {
  padding: 16px;
  width: 150px;
  font-weight: bold;
  font-size: $font-size-large;
  border: 1px solid $logo-magenta;
}

.button--primary-left {
  margin-right: 30px;
  color: $logo-magenta;
  background: white;
}

.button--darkblue,
.button--orange,
.button--darkgreen {
  padding: 13px;
  margin: 10px 0px;
  font-size: 13px;
  font-weight: bold;
  width: 100%;
}

.button--darkblue {
  background: $logo-magenta;
}

.darkblue {
  background: $logo-magenta;
}

.button--orange {
  border: none;
  background: $orange;
}

.button--secondary {
  width: 100%;
  font-size: $font-size-large;
  padding: $m-size;
  margin: 20px 0px;
}

.button--default {
  padding: 5px 10px;
  color: black;
  background: white;
  border: 1px solid black;
}

.button--small {
  font-size: 12px;
  padding: 6px 10px;
}

// .button--darkblue {
//   @extend .button;
//   padding: 10px;
//   background: $darkblue;
//   // border: 1.5px solid $darkblue;
//   // &:hover {
//   //   background: white;
//   //   color: $darkblue;
//   //   border: 1.5px solid $darkblue;
//   // }
// }

.button--black {
  font-size: 13px;
  padding: 8px 10px;
  background: black;
  border: 1px solid black;
  color: white;
  &:hover {
    color: black;
    background: white;
  }
}

.button--blue {
  padding: 13px 10px;
  background: $blue;
  border: 1.5px solid $blue;
  &:hover {
    background: white;
    color: $blue;
    border: 1.5px solid $blue;
  }
}

.button--medium {
  padding: 10px 40px;
}

.button-normal {
  @extend .button;
  padding: 8px 10px;
}

.button-small--white {
  padding: 8px 10px;
  background: white;
  border: 1px solid black;
  color: black;
  &:hover {
    color: white;
    background: black;
  }
}

.button--red__text {
  font-size: 12px;
  padding: 8px 10px;
  background: white;
  border: 1px solid red;
  color: red;
}

.preview-save-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .preview-btn,
  .save-btn {
    font-size: 18px;
    margin: 0px;
    padding: 10px;
    width: 100%;
    background: white;
    border: 1.5px solid $blue;
    color: $blue;
  }
  .preview-btn {
    margin-bottom: 20px;
  }
  @media (min-width: $tablet-breakpoint) {
    flex-direction: row;
    .save-btn {
      margin-left: 20px;
    }
    .preview-btn {
      margin: 0px;
      margin-right: 20px;
    }
  }
}

.card-btn {
  padding: 10px;
  width: 160px;
  margin: 10px 0px;
  color: $logo-magenta;
  text-align: center;
  background: whitesmoke;
  border: 1px solid $logo-magenta;
  i {
    float: left;
  }
}

.edit-text {
  color: $logo-magenta;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  padding: 0px 10px;
  border-radius: 0px;
  background: rgba(32, 28, 48, 0.1);
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
}

.delete-text {
  color: red;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  padding: 0px 10px;
  border-radius: 0px;
  background: rgba(32, 28, 48, 0.1);
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
}

.button--normal {
  font-weight: bold;
  border-radius: 0px;
  font-size: 12px;
  padding: 3px 6px;
}

.add-or-take-btn {
  font-size: 14px;
  margin: 10px 0px;
  padding: 10px;
  width: 100%;
  background: darkgreen;
  cursor: pointer;

  @media (min-width: $tablet-breakpoint) {
    margin: 20px;
    width: 120px;
  }
}

// .btn {
//   padding: 0px 15px;
//   height: 30px;
//   border-radius: 20px;
//   font-size: 12px;
//   font-weight: bold;
//   color: white;
//   background-color: rgba(255, 255, 255, 0.1);
//   cursor: pointer;
// }

.save-image-btn {
  right: 30px;
  bottom: 16%;
  padding: 5px;
  width: 100px;
  border-radius: 0px;
  font-size: 14px;
  text-align: center;
  color: white;
  position: absolute;
  background: rgba(32, 28, 48, 0.4);
  cursor: pointer;
  @media (min-width: 500px) {
    font-size: 18px;
  }
}

// .show-edit-view-share-btn {
//   // font-size: 15px;
//   // background: red;
//   margin-left: 5px;
//   display: block;
//   color: grey;
//   cursor: pointer;
//   @media (min-width: $tablet-breakpoint) {
//     display: none;
//   }
// }

// .edit-view-share-btn-container {
//   padding-top: 0px;
//   display: flex;

//   // display: none;
//   top: 5px;
//   right: 40px;

//   background: whitesmoke;
//   position: absolute;

//   justify-content: space-between;
//   .edit-container,
//   .view-container,
//   .share-container {
//     width: 100%;
//   }
//   .view-container {
//     margin-left: 12px;
//     margin-right: 12px;
//   }

//   .for-mobile {
//     padding: 3px 12px;
//     color: $logo-magenta;
//     display: block;
//     i {
//       cursor: pointer;
//     }
//   }

//   // .for-desktop {
//   //   display: none;
//   // }

//   @media (min-width: $tablet-breakpoint) {
//     padding-top: 12px;
//     background: transparent;
//     position: static;
//     .for-mobile {
//       display: none;
//     }

//     .for-desktop {
//       display: block;
//     }
//   }
// }

// .button:disabled {
//   opacity: 0.15;
//   &:hover {
//     opacity: 0.15;
//     cursor: not-allowed;
//   }
// }

// .radius-10 {
//   border-radius: 10px;
// }
// .radius-20 {
//   border-radius: 20px;
// }

// .border-green {
//   border: 2px solid $logo-magenta;
// }
// .border-white {
//   border: 2px solid white;
// }
// .border-black {
//   border: 2px solid black;
// }

.dashboard-active-btn {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #f1cfa6;
}

// .dashboard-container {
//     position: relative;
// }

// .blue {
//     color: $logo-magenta;
// }

// .background_blue {
//     background: $logo-magenta;
// }

// .fundraiser-max-text-width {
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     max-width: 350px;
// }

// .dashboard-container__left--shadow {
//     display: none;
//     @include media-breakpoint-up(lg) {
//         display: block;
//         // top: 90px;
//         top: 70px;
//     }
// }

// .dashboard-container__left--backdrop {
//     top: 0px;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     padding: 0px;
//     width: 100%;
//     background: rgba(32, 28, 48, 0.5);
//     position: fixed;
//     z-index: 500;
//     @include media-breakpoint-up(lg) {
//         // top: 90px;
//         top: 70px;
//         padding: 0px 70px;
//         width: 220px;
//     }
// }

// .dashboard-container__left {
//     // margin-top: 80px;
//     // width: 240px;
//     // height: 100%;
//     // color: white;
//     // background-image: linear-gradient(#d69e61, $logo-magenta);
//     // .dashboard-container__left__content {
//     //     width: 100%;
//     //     height: 100%;
//     //     position: relative;
//     // }
//     ul {
//         margin: 0px;
//         padding: 0px;
//         padding-top: 10px;
//         list-style: none;
//         left: 0px;
//         right: 0px;
//         // position: absolute;

//         li {
//             font-size: 15px;
//             padding: 12px 0px;
//             padding-left: 30px;
//             margin: 12px 10px;
//             margin-left: 0px;
//             font-weight: bold;
//             align-items: center;
//             display: flex;
//             cursor: pointer;
//             &:hover {
//                 color: whitesmoke;
//             }
//             label {
//                 cursor: pointer;
//             }
//             i {
//                 // background: red;
//                 width: 28px;
//                 font-size: 22px;
//                 margin-right: 12px;
//                 margin-top: -3px;
//                 text-align: center;
//             }
//             .home-icon {
//                 // background: red;
//                 width: 28px;
//                 font-size: 28px;
//                 margin-right: 12px;
//                 margin-top: -3px;
//             }
//             img {
//                 // background: red;
//                 margin-right: 12px;
//                 width: 28px;
//                 height: auto;
//             }
//         }

//         .active-btn {
//             border-top-right-radius: 10px;
//             border-bottom-right-radius: 10px;
//             background: #f1cfa6;
//         }
//     }

//     @include media-breakpoint-up(lg) {
//         // margin-top: 0px;
//         // margin-left: -80px;
//         // padding: 20px 0px;
//         // border: none;
//         // border-radius: 0px;
//         // position: static;
//         // background: transparent;
//         .fa-angle-container {
//             display: none;
//         }
//     }
// }

// .notification {
//     margin-bottom: 15px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 0px 15px;
//     border-radius: 10px;
//     border: 1px solid grey;
//     i {
//         font-size: 18px;
//     }
// }

// .account-btn {
//     padding: 12px 0px;
//     margin: 50px 0px;
//     color: $logo-magenta;
//     border: 1.5px solid $logo-magenta;
//     border-radius: 5px;
//     width: 260px;
//     align-items: center;
//     display: flex;
//     cursor: pointer;

//     i,
//     .share-icon {
//         text-align: center;
//         font-size: 22px;
//         width: 30px;
//         margin-left: 20px;
//         margin-right: 20px;
//         // background: red;
//     }

//     label {
//         font-size: 15px;
//         cursor: pointer;
//     }

//     span {
//         font-weight: bold;
//         font-size: 15px;
//     }
// }

// .image-container {
//     display: flex;
//     align-items: center;
//     flex-direction: column;

//     .fa-user-circle {
//         font-size: 140px;
//         color: $backDropColor;
//     }
//     img {
//         height: 170px;
//         width: 170px;
//         border-radius: 50%;
//     }
//     .image-btn-container {
//         display: flex;
//         padding-bottom: 5px;
//     }
//     .image-container__text {
//         color: $logo-magenta;
//         font-size: 12px;
//         text-align: center;
//     }
//     .change-btn,
//     .remove-btn {
//         border-radius: 20px;
//         padding: 1px 15px;
//         font-size: 13px;
//         color: white;
//         cursor: pointer;
//     }
//     .change-btn {
//         background: $blue;
//     }
//     .remove-btn {
//         background: $backDropColor;
//     }
// }

// .inner-section {
//     padding: 10px 20px;
//     h2 {
//         font-size: 19px;
//         color: $logo-magenta;
//     }
// }

// .inner-content {
//     padding: 10px;
//     width: 100%;
//     @include media-breakpoint-up(md) {
//         padding: 10px 15px;
//         @include media-breakpoint-up(lg) {
//             padding: 6px 20px;
//         }
//     }
// }

// .inner-container-top {
//     padding: 20px 40px;
//     border-radius: 20px;
//     .inner-container-top__content {
//         display: block;
//     }
//     .inner-container-top__content__right {
//         flex-direction: column;
//         display: flex;
//         align-items: center;
//         img {
//             width: 100%;
//             margin-top: 0px;
//             margin-bottom: 15px;
//         }
//     }

//     .inner-container-top__content__left,
//     .inner-container-top__content__right {
//         width: 100%;
//     }

//     h1 {
//         font-size: 20px;
//         color: $logo-magenta;
//     }
//     @include media-breakpoint-up(md) {
//         .inner-container-top__content {
//             display: flex;
//         }
//         h1 {
//             font-size: 22px;
//         }
//     }
//     @include media-breakpoint-up(lg) {
//         .inner-container-top__content__right {
//             img {
//                 margin-top: -30px;
//             }
//         }
//         .inner-container-top__content__left {
//             width: 60%;
//         }
//         .inner-container-top__content__right {
//             width: 40%;
//         }

//         h1 {
//             font-size: 30px;
//         }
//     }

//     h3 {
//         color: $logo-magenta;
//         padding-top: 20px;
//         margin-bottom: -5px;
//     }
//     p {
//         display: flex;
//         align-items: center;
//         i {
//             line-height: 28px;
//             font-size: 28px;
//             margin-right: 10px;
//         }
//         span {
//             color: grey;
//         }
//     }
// }

// .dot {
//     padding: 10px 0px;
//     display: flex;
//     align-items: center;
//     i {
//         font-size: 10px;
//         color: grey;
//     }
//     span {
//         width: 120px;
//         &:hover {
//             font-weight: bold;
//             cursor: pointer;
//         }
//     }
// }

// .hide-input-container {
//     margin-left: 30px;
//     .hide-input {
//         align-items: center;
//         display: flex;
//         .input {
//             width: 300px;
//             margin-right: 30px;
//         }
//     }
// }
// .fundraiser-see-more {
//     color: grey;
//     align-items: center;
//     display: flex;
//     float: right;
//     cursor: pointer;
//     .fa-angle-right {
//         padding-left: 10px;
//         font-size: 28px;
//     }
// }

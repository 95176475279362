.app-router__fixed-header {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 5000; 
}

.app-router__fixed-header__page {
  // height: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.app-router__internet-warning {
  top: 28px;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 5000; 
}

